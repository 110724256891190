/* eslint-disable */
declare global {
  interface Window {
    __REACT_APP_BASE_URL__: string
    __REACT_APP_BLOCKCHAIN_NETWORK_URL__: string
    __REACT_APP_STABILITY_BLOCKCHAIN_NETWORK_URL__: string
    __REACT_APP_DROP_BOX_FETCH_REFRESH_TOKEN_URL__: string
  }
}

const REACT_APP_BASE_URL: string = window.__REACT_APP_BASE_URL__ || ''
const REACT_APP_BLOCKCHAIN_NETWORK_URL: string = window.__REACT_APP_BLOCKCHAIN_NETWORK_URL__ || ''

const __REACT_APP_STABILITY_BLOCKCHAIN_NETWORK_URL__: string =
  window.__REACT_APP_STABILITY_BLOCKCHAIN_NETWORK_URL__ || ''

  const REACT_APP_DROP_BOX_FETCH_REFRESH_TOKEN_URL: string =
  window.__REACT_APP_DROP_BOX_FETCH_REFRESH_TOKEN_URL__ || ''
  
export default {
  REACT_APP_BASE_URL,
  BLOCKCHAIN_NETWORK_URL: REACT_APP_BLOCKCHAIN_NETWORK_URL,
  STABILITY_BLOCKCHAIN_NETWORK_URL: __REACT_APP_STABILITY_BLOCKCHAIN_NETWORK_URL__,
  DROP_BOX_FETCH_REFRESH_TOKEN_URL: REACT_APP_DROP_BOX_FETCH_REFRESH_TOKEN_URL,
}
